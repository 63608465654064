<template>
    <div id="jobDetail">
        <div class="mcontainer">
            <div class="card">
                <div class="mb-4 overflow-hidden relative rounded-t-lg w-full" v-if="resources.link !== ''">
                    <iframe width="100%" height="400px" :src="resources.link" title="Video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="h-44 mb-4 md:h-72 overflow-hidden relative rounded-t-lg w-full" v-else>
                    <img :src="resources.images" alt="" class="w-full h-full absolute inset-0 object-cover">
                </div>
                <div class="p-4" v-if="resources">
                    <h1 class="lg:text-2xl text-xl font-semibold mb-6">{{ resources.title }}</h1>
                    <div class="md:flex items-center md:space-x-3 my-3 pb-4 border-b" v-if="resources.created_by">
                        <div class="flex mb-3">
                            <img :src="resources.created_by.images !== '' ? resources.created_by.images : require('@/assets/images/avatars/avatar-1.jpg')" class="w-10 h-10 rounded-full mr-3">
                            <div>
                                <div class="text-base font-semibold"> {{ resources.created_by.fullname }} </div>
                                <div class="text-xs">{{ resources.type }} . Published on {{ resources.created_at }} </div>
                            </div>
                        </div>
                        <div class="md:absolute right-4 action-buttton mb-3" v-if="datauser.is_admin === 1">
                            <a v-if="resources.approved === 0" class="btn btn-blue px-4 mr-2" v-b-tooltip.hover title="Update Data"  @click="handleApprove">
                                <span>Approve</span>
                            </a>
                            <router-link :to="{ name: 'UpdateResource', params: {code: resources.slug } }" class="btn btn-blue px-4 mr-2" v-b-tooltip.hover title="Update Data">
                                <span>Edit</span>
                            </router-link>
                            <button @click="handleDelete" class="btn btn-danger p-2" v-b-tooltip.hover title="Delete Data">
                                <Icon icon="bi:trash" />
                            </button>
                        </div>
                    </div>
                    <div class="space-y-3">
                        <!-- <img :src="resources.images" alt=""><br> -->
                        <div class="row">
                            <div class="col-md-5 col-lg-4 mb-4 order-md-2">
                                <div class="card p-3" style="background: #e5e7eb;">
                                    <table>
                                        <tr v-if="resources.author">
                                            <td><b>Author</b></td>
                                            <td>:</td>
                                            <td>{{ resources.author }}</td>
                                        </tr>
                                        <tr v-if="resources.creator">
                                            <td><b>Creator</b></td>
                                            <td>:</td>
                                            <td>{{ resources.creator }}</td>
                                        </tr>
                                        <tr v-if="resources.publisher">
                                            <td><b>Publisher</b></td>
                                            <td>:</td>
                                            <td>{{ resources.publisher }}</td>
                                        </tr>
                                        <tr v-if="resources.publication_year">
                                            <td><b>Publication Year</b></td>
                                            <td>:</td>
                                            <td>{{ resources.publication_year }}</td>
                                        </tr>
                                        <tr v-if="resources.numb_of_page">
                                            <td><b>Number of Page</b></td>
                                            <td>:</td>
                                            <td>{{ resources.numb_of_page }}</td>
                                        </tr>
                                        <tr v-if="resources.journal_volume">
                                            <td><b>Journal Volume</b></td>
                                            <td>:</td>
                                            <td>{{ resources.journal_volume }}</td>
                                        </tr>
                                        <tr v-if="resources.language">
                                            <td><b>Language</b></td>
                                            <td>:</td>
                                            <td>{{ resources.language }}</td>
                                        </tr>
                                        <tr v-if="resources.translator">
                                            <td><b>Translator</b></td>
                                            <td>:</td>
                                            <td>{{ resources.translator }}</td>
                                        </tr>
                                        <tr v-if="resources.document_type">
                                            <td><b>Document Type</b></td>
                                            <td>:</td>
                                            <td>{{ resources.document_type }}</td>
                                        </tr>
                                        <tr v-if="resources.file">
                                            <td><b>File</b></td>
                                            <td>:</td>
                                            <td><a :href="resources.file" target="_blank" style="text-decoration: underline;">Download Here</a></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-7 col-lg-8">
                                <div v-html="resources.description"></div>
                                <div v-if="resources.content" class="mt-4">
                                    <strong>Content</strong>
                                    <div v-html="resources.content"></div>
                                </div>
                                <div v-if="resources.file_name.split('.').pop() === 'pdf'">
                                    <br><br>
                                    <iframe :src="'https://flowpaper.com/flipbook/'+resources.file" width="100%" height="600" allowFullScreen />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br><br>
                    <hr>
                    <small v-if="resources.category">Category : {{ resources.category.title }} | Tags : {{ resources.tags }}</small>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import { Icon } from '@iconify/vue2'
    import { mapState } from 'vuex'
    import axios from 'axios'

    export default {
        computed: mapState('auth', { datauser: 'user' }),
        components: {
            Icon
        },
        data () {
            return {
                resources: {}
            }
        },
        async created () {
            try {
                const res = await axios.get('resource/' + this.$route.params.code)
                this.resources = res.data.result
            } catch (e) {
                console.error(e)
            }
        },
        methods: {
            handleDelete: function () {
                const parents = this
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.get('resource/delete/' + this.resources.id).then(function (response) {
                            if (response.data.status) {
                                parents.$router.push({ name: 'Resources' })
                            }
                        }).catch(function () {
                            parents.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something Wrong'
                            })
                        })
                    }
                })
            },
            handleApprove: function () {
                const parents = this
                this.$swal({
                    title: 'Are you sure want to approve this resource?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.post('resource/approve/' + this.resources.id).then(function (response) {
                            if (response.data.status) {
                                parents.$router.push({ name: 'ApprovalResource' })
                            }
                        }).catch(function () {
                            parents.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something Wrong'
                            })
                        })
                    }
                })
            }
        }
    }
</script>
